import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { AreaChart, Area, ReferenceArea, ReferenceLine, Symbols, XAxis, YAxis, Surface, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CSVLink } from 'react-csv';

import ExportButton from '@material-ui/icons/SaveAltRounded';
import { CircularProgress, Grid, IconButton, ImageList, Menu, MenuItem, Typography } from '@material-ui/core';
import { alpha, lighten, withStyles } from '@material-ui/core/styles';

import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks } from '../../utils/colors';
import { saveImage, reduceArray, getColor, getCordinates } from '../../utils/helpers';

const SIZE = 32;


const styles = theme => ({
  menuItem: {
    // color: theme.palette.primary.main
  },
  gridList: {
    // width: '100%',
    height:'100%',
    overflowX:'hidden',
    overflowY:'hidden',
    // height: 100,
    // flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)',
  },
});

function CustomTooltip({ payload, label, active, multDates, singleLocation, numPlots, calIdx, mode, timeInterval }) {
  // let dateStr = moment(label).tz('America/New_York').format('h:mm A') + ' - ' + moment(label).tz('America/New_York').add(15,'minutes').format('h:mm A');
  let dateStr = moment(label).tz('America/New_York').format('h:mm A') + ' - ' + moment(label).tz('America/New_York').add(timeInterval, 'minutes').format('h:mm A');

  if (active && payload) {
    const countMinSize = 6;
    let dataName = []
    let numLines = payload.length;
    let fontAdjust = 12 - numPlots - (numLines * 1.5);
    if (multDates) {
      fontAdjust = fontAdjust / 1.7;
    }

    for (let p = 0; p < numLines; p++) {
      dataName[p] = payload[p].name.split(': ');
    }

    return (
      <div>
        <Grid container alignItems={'flex-end'} style={{ textAlign: 'left', paddingTop: 0, paddingLeft: 1, paddingRight: 0, paddingBottom: Math.max(countMinSize, fontAdjust) / 4, backgroundColor: alpha('#ffffff', 0.2) }}>
          <Typography style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 4px + 0.5vw)', paddingRight: 5 }}>
            {dateStr}
          </Typography>
        </Grid>
      { payload.map( (p, index) => (
        <Grid key={index} container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ textAlign: 'left', paddingTop: Math.max(countMinSize, fontAdjust) / 2, paddingBottom: Math.max(countMinSize, fontAdjust), paddingLeft: Math.max(countMinSize, fontAdjust), paddingRight: Math.max(countMinSize, fontAdjust), marginBottom: 1 + (Math.max(countMinSize, fontAdjust) / 2), backgroundColor: alpha(lighten(p.color, 0.3), 0.7) }}>
          <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
            <Typography style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)' }}>
              {mode === 'Conversion' ? `${(p.value).toLocaleString()}%` : (mode === 'Dwell') ? `${(p.value).toLocaleString()}s` : `${Math.round(p.value).toLocaleString()} `}
            </Typography>
            <Typography style={{ display: singleLocation ? '' : '', color: 'white', fontWeight: 500, paddingLeft: 20, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)' }}>
              {dataName[index][0]}
            </Typography>
            <Typography style={{ display: (dataName[index].length > 1 && !singleLocation) ? '' : 'none', color: 'white', fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)', textAlign: 'right'}}>
              {': ' + dataName[index][1]}
            </Typography>
          </Grid>
        </Grid>
      ))}
      </div>
    );
  }

  return null;
}


class ByTimeChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.series,
      anchorEl: null,
      exportData: [],
      exportHeaders: null,
      dateRange: '',
      // showCopied: false,
      colors: this.props.locationsObject['colors'],
      COMBCOLORS: this.props.locationsObject['COMBCOLORS'],
      labelList: this.props.locationsObject['labelList'],
      refAreaLeft: '',
      refAreaRight: '',
      refAreaSet: false,
      count: this.props.count,
      gender: this.props.gender,
      age: this.props.age,
      multDates: this.props.multDates,
      numPlots: this.props.numPlots,
      CELLWIDTH: this.props.CELLWIDTH,
    };

    this.currentChart = React.createRef();
    this.percCounts = []; // new Array(this.props.locationsObject.labelList.length);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    if (nextProps.series !== prevState.series) {
      let retObj = {
        series: nextProps.series,
        multDates: nextProps.multDates,
        numPlots: nextProps.numPlots
      }

      if (prevState.refAreaSet && nextProps.mode.toLowerCase() !== 'traffic') {
        retObj = { ...retObj, refAreaLeft: '', refAreaRight: '', refAreaSet: false }; // clear percCounts if displayed and mode changed
      }

      return retObj;
    }

    return {};
  }

  getStroke() {
    if (this.state.multDates) {
      return 5;
    } else {
      return '';
    }
  }


  renderIcon(data) {
    const { inactiveColor } = this.props;
    const halfSize = SIZE / 2;
    const sixthSize = SIZE / 6;
    const thirdSize = SIZE / 3;
    const color = data.inactive ? inactiveColor : data.color;
    var newStroke = data.payload.strokeDasharray;
    if (newStroke !== '') {
      newStroke = '5 3'
    }

    if (data.type === 'plainline') {
      return (
        <line
          strokeWidth={4}
          fill="none"
          stroke={color}
          strokeDasharray={newStroke}
          x1={0}
          y1={halfSize}
          x2={SIZE}
          y2={halfSize}
          className="recharts-legend-icon"
        />
      );
    } if (data.type === 'line') {
      return (
        <path
          strokeWidth={4}
          fill="none"
          stroke={color}
          strokeDasharray={newStroke}
          d={`M0,${halfSize}h${thirdSize}
            A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
            H${SIZE}M${2 * thirdSize},${halfSize}
            A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
          className="recharts-legend-icon"
        />
      );
    } if (data.type === 'rect') {
      return (
        <path
          stroke="none"
          fill={color}
          d={`M0,${SIZE / 8}h${SIZE}v${SIZE * 3 / 4}h${-SIZE}z`}
          className="recharts-legend-icon"
        />
      );
    }

    return (
      <Symbols
        fill={color}
        cx={halfSize}
        cy={halfSize}
        size={SIZE}
        sizeType="diameter"
        type={data.type}
      />
    );
  }

  renderLegend = (props) => {
    const { payload } = props;
    const percCounts = this.percCounts;
    const numLines = (this.state.multDates) ? (this.state.numPlots + 1) : (this.state.numPlots);
    const active = payload.filter(area => !area.inactive);

    let ICONSIZE = 24;
    const viewBox = { x: 0, y: 0, width: ICONSIZE, height: ICONSIZE };
    ICONSIZE = (4.5 * Math.sqrt(window.innerWidth / 100)) - (numLines * 0.5);
    if (this.state.multDates) {
      ICONSIZE = ICONSIZE / 1.3;
    }
    let fontAdjust = ICONSIZE;

    return (
      <ul className='recharts-default-legend' style={{ position: 'absolute', width: '25vw', top: 0, right: 0 }}>
      { active.map( (entry, index) => (
        <li key={`item-${index}-${entry.dataKey}`} style={{ display: entry.inactive ? 'none' : 'inline', textAlign: 'left' }}>
          <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: ICONSIZE / 3, display: '', backgroundColor: alpha('#ffffff', 0.2) }}>
            <Surface xs={1} width={ICONSIZE * 2} height={ICONSIZE * 1.1} viewBox={viewBox} style={{ display: '', paddingRight: fontAdjust / 2 }}>
              {this.renderIcon(entry)}
            </Surface>
            <Grid container xs item style={{ display: '', paddingRight: fontAdjust / 2 }}>
              <Typography style={{ fontSize: fontAdjust }} className='recharts-legend-item-text'>
                {entry.value}
              </Typography>
            </Grid>
            <Grid container xs={1} item direction='row' justifyContent='flex-end' style={{ display: '', paddingRight: fontAdjust }}>
              {/* <Typography align='right' style={{ fontWeight: 700, fontSize: fontAdjust, paddingLeft: fontAdjust, display: isNaN(percCounts[index / 6]) ? 'none' : '' }} className='recharts-legend-item-text'> */}
              <Typography align='right' style={{ fontWeight: 700, fontSize: fontAdjust, paddingLeft: fontAdjust, display: isNaN(percCounts[index]) ? 'none' : '' }} className='recharts-legend-item-text'>
                {/* {parseInt(percCounts[index / 6] * 100) + '%'} */}
                {parseInt(percCounts[index] * 100) + '%'}
              </Typography>
            </Grid>
          </Grid>
        </li>
      ))}
      </ul>
    );
  }

  clickExport = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  exportData = (activeData) => () => {
    let exportData = [];

    for (let s = 0; s < activeData.length; s++) {
      const { lat, lon } = (activeData[s].data.length > 0) ? getCordinates(activeData[s].locationLabels, this.props.locationsObject) : { lat: '', lon: '' };

      let partData = activeData[s].data;

      for (let d = 0; d < partData.length; d++) {
        exportData.push({});
        let curIdx = exportData.length - 1;
        let tempObj = {};

        tempObj['Location(s)'] = activeData[s].locationLabels ?? '';
        tempObj['Date Range'] = activeData[s].dateLabel ?? '';
        tempObj['Time'] = (partData[d]._id?.time) ? moment(partData[d]._id.time).tz('America/New_York').format('h:mm A') : '';
        tempObj['latitude'] = lat;
        tempObj['longitude'] = lon;

        if (this.props.mode !== 'Conversion') {
          if (this.state.count) { tempObj['Count'] = partData[d]?.count?.toFixed(0) ?? 0 };
          if (this.state.gender) { tempObj['Male'] = partData[d]?.men?.toFixed(0) ?? 0; tempObj['Female'] = partData[d]?.women?.toFixed(0) ?? 0; };
          if (this.state.age) { tempObj['Adults'] = partData[d]?.adults?.toFixed(0) ?? 0; tempObj['Children'] = partData[d]?.children?.toFixed(0) ?? 0; tempObj['Seniors'] = partData[d]?.seniors?.toFixed(0) ?? 0; };
        }
        else {
          if (this.state.count) { tempObj['Count'] = partData[d]?.count ?? 0 };
          if (this.state.gender) { tempObj['Male'] = partData[d]?.men ?? 0; tempObj['Female'] = partData[d]?.women ?? 0; };
          if (this.state.age) { tempObj['Adults'] = partData[d]?.adults ?? 0; tempObj['Children'] = partData[d]?.children ?? 0; tempObj['Seniors'] = partData[d]?.seniors ?? 0; };
        }

        exportData[curIdx] = tempObj;
      }
    }

    this.setState({
      exportData: exportData, dateRange: activeData[0]?.dateLabel ?? '', anchorEl: null
    });
  }

  downloadImage = async (format) => {
    try {
      const fileObj = {
        chartName: 'ExterosChart_ByTimeOfDay_',
        dateLabel: this.state.series[0].dateLabel,
        count: this.state.count,
        gender: this.state.gender,
        age: this.state.age,
        fileType: format
      }

      saveImage(this.currentChart.current, fileObj, false);
    } catch (err) {
      // notify user of error
    } finally {
      this.handleClose();
    }
  }

  mouseDown = (event) => {
    if (this.state.refAreaSet) {
      this.percCounts = [];
      this.setState({ refAreaLeft: '', refAreaRight: '', refAreaSet: false }); // (third click) - resets state variables and clears chart overlays/legend
    }
    else if (this.state.refAreaLeft && event != null) {
      this.setState({ refAreaSet: true }); // (second click) - 
    }
    else {
      if (event != null) this.setState({ refAreaLeft: event.activeLabel }); // (first click) - null if user clicks in chart margin
    }      
  }

  mouseMove = (activeData) => (event) => {
    if (this.state.refAreaLeft && !this.state.refAreaSet) {
      let percCounts = this.percCounts;
      let startTime = moment(this.state.refAreaLeft);
      let endTime = moment(event.activeLabel);

      if (startTime.isSameOrAfter(endTime)) {
        let swapTime = startTime;
        startTime = endTime;
        endTime = swapTime;
      }

      let curTime = [];
      for (let ser in activeData) {
        let curCount = 0;

        if (typeof(activeData[ser].totalCounts) !== 'undefined') {
          for (let d in activeData[ser].data) {
            curTime = moment(activeData[ser].data[d]._id.time);

            if (curTime.isSameOrAfter(startTime) && endTime.isSameOrAfter(curTime)) {
              curCount += activeData[ser].data[d].count;
            }
          }
          percCounts[parseInt(ser)] = curCount / activeData[ser].totalCounts['count'];
        }
      }

      this.setState({ refAreaRight: event.activeLabel });
    }
  }

  getPercCounts = (activeData) => {
    let percCounts = this.percCounts;
    let startTime = moment(this.state.refAreaLeft);
    let endTime = moment(this.state.refAreaRight);

    if (startTime.isSameOrAfter(endTime)) {
      let swapTime = startTime;
      startTime = endTime;
      endTime = swapTime;
    }

    let curTime = [];
    for (let ser in activeData) {
      let curCount = 0;

      if (typeof(activeData[ser].totalCounts) !== 'undefined') {
        for (let d in activeData[ser].data) {
          curTime = moment(activeData[ser].data[d]._id.time);

          if (curTime.isSameOrAfter(startTime) && endTime.isSameOrAfter(curTime)) {
            curCount += activeData[ser].data[d].count;
          }
        }
        percCounts[parseInt(ser)] = curCount / activeData[ser].totalCounts['count'];
      }

      if (curCount === 0) {
        percCounts[parseInt(ser)] = NaN;
      }
    }
  }

  getChartTicks = (chartArray, dataPoints, multDates, genderTab, ageTab, tickOffset) => {
    if (dataPoints === 0) return { ticksArray: [], ticksObj: {} };

    let [min, max, ticksArray, ticksObj] = [0, 0, [], {}];

    chartArray.forEach((val, i) => {  
      if (val.data?.length > 0) {
        ticksObj[i] = {
          min: val.data[0]?._id?.time ?? 0,
          max: val.data[val.data.length - 1]?._id?.time ?? 0,
          ticksArray: []
        }

        if (min === 0 || min > val.data[0]?._id?.time) min = val.data[0]._id.time;
        if (max === 0 || max < val.data[val.data.length - 1]?._id?.time) max = val.data[val.data.length - 1]._id.time;
      }
      else {
        ticksObj[i] = { min: 0, max: 0, ticksArray: [] }
      }
    });

    if (multDates || (!genderTab && !ageTab)) {
      if (min !== 0) {
        ticksArray.push(min);
  
        while (min <= max) {
          min = min + (tickOffset);
          if (min <= max) ticksArray.push(min);
        }
      }
    }

    if (!multDates && (genderTab || ageTab)) {
      for (let i = 0; i < Object.keys(ticksObj).length; i++) {
        if (ticksObj[i]) {
          if (ticksObj[i].min !== 0) {
            ticksObj[i].ticksArray.push(ticksObj[i].min);

            while (ticksObj[i].min <= ticksObj[i].max) {
              ticksObj[i].min = ticksObj[i].min + tickOffset;
              if (ticksObj[i].min <= ticksObj[i].max) ticksObj[i].ticksArray.push(ticksObj[i].min);
            }
          }
        }
        else {
          ticksObj[i] = { ticksArray: [] }
        }
      }
    }

    return { ticksArray: ticksArray, ticksObj: ticksObj };
  }

  render() {
    const { age, classes, count, dateLabel, gender, mode, multDates, numPlots, openTab, vehicles, windowHeight, windowWidth } = this.props;
    const yAxisFontSize = 'calc('.concat((8 - (Math.round(numPlots / 2))), 'px + 0.4vw)');

    let [dataPoints, secondDateCardIndex] = [0, 0];
    try { secondDateCardIndex = this.state.series.length - 1; }
    catch (e) {}

    // clear percCounts if displayed and mode changed - state variables (refAreaLeft, refAreaRight, refAreaSet) updated in getDerivedStateFromProps
    if (mode.toLowerCase() !== 'traffic' && this.state.refAreaSet) this.percCounts = []; // refAreaSet will still be true on first render after mode change

    let chartArray = reduceArray(this.state.series, numPlots, multDates, secondDateCardIndex);
    for (let i in chartArray) {
      chartArray[i].colorIndex = i; // track locations original index before sorting so combination color matches other charts.
    }

    let chartArrayNoSort = JSON.parse(JSON.stringify(chartArray)); // used w/ getColors function

    if (openTab === 0 && (numPlots > 1 || !multDates)) {
      chartArray.sort((a, b) => {
        if (b.totalCounts && b.totalCounts.count && a.totalCounts && a.totalCounts.count) {
          return (b.totalCounts.count - a.totalCounts.count); // change chart display order by size (largest in back)
        }
      });
    }

    try {
      for (let ser in chartArray) {
        if (chartArray[ser].data.length > dataPoints) {
          dataPoints = chartArray[ser].data.length;
        }
      }
    } catch (err) {}

    if (mode.toLowerCase() === 'traffic' && this.state.refAreaSet && typeof(this.percCounts[0]) !== 'undefined') {
      // check if percCount is currently displayed, update to reflect new data/locations/dates.
      // ByTimeChart renders twice when receiving new data (date/time data - getDailyAverages/getDataByTime), make sure necc data is available before updating PercCounts.
        // this is needed to update percCounts when either date or location is changed, unnecessarily runs 1 time after user selects a time range (counts already set in mouseDown).
      if (!multDates) {
        if (chartArray.length === numPlots && chartArray.every(data => data.dateLabel === dateLabel[0])) {
          this.getPercCounts(chartArray);
        }
      }
      else {
        if ((chartArray.every(data => data.dateLabel.length > 0))) {
          this.getPercCounts(chartArray);
        }
      }
    }

    // configure x-axis ticks
    let hrOffset = 1;
    if (multDates || (!gender && !age)) {
      hrOffset = (windowWidth < 650) ? 3 : 2;
    }
    else {
      hrOffset = (chartArray.length > 1) ? 3 : 2;
    }
    const tickOffset = 60 * 60 * 1000 * hrOffset; // (60 * 60 * 1000 * 2) 2 hr increments
    const { ticksArray, ticksObj } = this.getChartTicks(chartArray, dataPoints, multDates, gender, age, tickOffset);

    return (
      <Grid style={{ width: '100%', backgroundColor: 'white', height: (windowWidth < this.state.CELLWIDTH && numPlots <= 2) ? '40vh' : '60vh' }}>
        <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ height: '5vh', width: '100%', backgroundColor: 'white' }}>
          <Grid xs item style={{ marginTop: '0.75vw', marginLeft: '1.5vw', marginBottom: 0 }}>
            <Grid container alignItems='flex-end'>
              <Typography align='left' style={{ cursor: 'pointer', paddingTop: '0px', paddingBottom: '0px', fontSize: 'calc(12px + 0.6vw)' }}>
                {this.props.mode === 'Conversion'
                  ? 'Capture by Time of Day'
                  : this.props.mode === 'Dwell'
                    ? 'Avg Dwell by Time of Day'
                    : (this.props.occupancy)
                      ? 'Peak Occupancy'
                      : 'Traffic by Time of Day'
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: '1.5vh' }}>
            <Grid container direction='row' alignItems='center'>
            { gender && !multDates && !vehicles &&
              <Grid container direction='row' alignItems='center' style={{ marginTop: 0 }}>
                <Grid style={{ display: 'inline', marginRight: 'calc(2px + 0.1vw)', width: 'calc(8px + 0.6vw)', height: 'calc(8px + 0.6vw)', backgroundColor: colorsWomen[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.6vw)', display: 'inline' }}>Female</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.6vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.6vw)', height: 'calc(8px + 0.6vw)', backgroundColor: colorsMen[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.6vw)', display: 'inline' }}>Male</Typography>
              </Grid>
            }
            { age && !multDates && !vehicles &&
              <Grid container direction='row' alignItems='center' style={{ marginTop: '0.5vh' }}>
                <Grid style={{ display: 'inline', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsChildren[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Children</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsAdults[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Adults</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsSeniors[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Seniors</Typography>
              </Grid>
            }
            { vehicles && gender && !multDates &&
              <Grid container direction='row' alignItems='center' style={{ marginTop: '0.5vh' }}>
                <Grid style={{ display: 'inline', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsBikes[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Bikes</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsBuses[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Buses</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsCars[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Cars</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsTrucks[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Trucks</Typography>
              </Grid>
            }
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid container alignItems='flex-start' justifyContent='flex-end'>
              <Grid item>
                <IconButton className={classes.exportBtn} onClick={this.clickExport}>
                  <ExportButton />
                </IconButton>
                <Menu
                  id='export-menu'
                  anchorEl={this.state.anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}>
                  <MenuItem onClick={this.exportData(chartArray)} className={classes.menuItem} disabled={(dataPoints > 0 && !vehicles) ? false : true}>
                    <CSVLink
                      data={this.state.exportData}
                      filename={'ExterosData_ByTimeOfDay_'.concat(this.state.dateRange, '_', count ? 'Counts' : '', gender ? 'Gender' : '', age ? 'Age' : '', '.csv').replace(/\s/g, '')}
                      style={{ textDecoration: 'none', color: 'inherit' }}>
                      CSV (.csv)
                    </CSVLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('jpeg')}>Image (.jpeg)</MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('svg')}>Image (.svg)</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='column' ref={this.currentChart} justifyContent='flex-start' alignItems='center' style={{ position: 'relative', width: '100%', backgroundColor: 'white', height: (windowWidth < this.state.CELLWIDTH && numPlots <= 2) ? '34vh' : '54vh' }}>
          <Typography style={{ fontSize: 'calc(8px + 0.4vw)', visibility: dataPoints > 0 ? 'visible' : 'hidden', position: 'absolute', top: (windowWidth < this.state.CELLWIDTH && numPlots <= 2) ? windowHeight / 7.4 : windowHeight / 4.2, left: 0, display: 'block', transformOrigin: 'center', transform: 'translate(0px,0px) rotate(-90deg)' }}>
            {mode === 'Conversion' ? 'Percent' : (mode === 'Dwell') ? 'Seconds' : 'Counts'}
          </Typography>
          <Typography style={{ fontSize: 'calc(8px + 0.4vw)', visibility: dataPoints > 0 ? 'visible' : 'hidden', position: 'absolute', left: '50%', bottom: '0vh', display: 'block' }}>
            Time
          </Typography>
          {/* <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showCopied}
            autoHideDuration={1500}
            transitionDuration={{ enter: 600, exit: 1000 }}
            onClose={this.messageClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Chart Copied to Clipboard</span>}
          /> */}
        { dataPoints === 0 &&
          <Grid container justifyContent='space-around' alignItems='center'>
            <CircularProgress style={{ position: 'absolute', top: windowHeight / 4.2, left: windowWidth / 2.4 }}/>
          </Grid>
        }
        { dataPoints > 0 && (multDates || (!gender && !age)) &&
          <Grid container item className={classes.gridList} style={{ backgroundColor: 'white', marginTop: '0px', marginBottom: 0, marginLeft: '0px' }}>
            <ResponsiveContainer width={'100%'} height={'100%'} maxHeight={'100vh'} minHeight={50}>
              <AreaChart
                style={{ cursor: 'pointer' }}
                margin={{
                  left: windowWidth < 600 ? -windowWidth * 0.02 : windowWidth * 0.01,
                  bottom: windowWidth < 600 ? windowHeight * 0.01 : windowHeight * 0.03,
                  right: windowWidth < 600 ? windowWidth * 0.015 : windowWidth * 0.015
                }}
                onMouseDown={(count && mode.toLowerCase() === 'traffic') ? this.mouseDown : ()=>{}}
                onMouseMove={(count && mode.toLowerCase() === 'traffic' && !this.state.refAreaSet && (this.state.refAreaLeft !== '' || this.state.refAreaRight !== '')) ? this.mouseMove(chartArray) : ()=>{}}
              >
                <XAxis
                  type='number'
                  dataKey='_id.time'
                  domain={['dataMin', 'dataMax']}
                  style={{ fontSize: 'calc(8px + 0.4vw)' }}
                  allowDuplicatedCategory={false}
                  dy={6}
                  interval={0}
                  scale='time'
                  ticks={ticksArray}
                  tickFormatter={unixTime => moment(unixTime).tz('America/New_York').format('h A')}
                  // label={{ value: 'Time of Day', position: 'bottom', fontSize: 'calc(10px + 0.4vw)', dy: (windowWidth / 60-15) }}
                />
                <YAxis
                  yAxisId='1'
                  type='number'
                  style={{ fontSize: 'calc(8px + 0.4vw)' }}
                  dx={0}
                  // tickMargin={-10}
                  tickCount={6}
                  domain={[
                    dataMin => 0,
                    dataMax => (Math.round((dataMax * 1.5) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))
                  ]}
                  tickFormatter={
                    mode === 'Conversion'
                      ? (tickItem) => { return ((tickItem / 1).toLocaleString() + ' %') }
                      : (tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }
                  }
                >
                  {/* <Label value= 'Counts' position= 'left' angle= {-90} dx={30-(windowWidth/60)} fontSize={'calc(10px + 0.4vw)'} /> */}
                </YAxis>
                <Tooltip position={{ y: 0 }} content={<CustomTooltip multDates={multDates} singleLocation={false} numPlots={1} calIdx={0} mode={mode} timeInterval={this.props.toolTipInterval}/>}/>

              { openTab === 0 &&
                chartArray.map( (s, idx) => (
                  [
                    <Area
                      key={s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1'
                      isAnimationActive={true}
                      type='monotoneX'
                      data={s.data}
                      dataKey='count'
                      stroke={getColor(s.locationLabels, s.colorIndex, this.props.locationsObject, chartArrayNoSort, true)}
                      strokeWidth={this.getStroke()}
                      fill={getColor(s.locationLabels, s.colorIndex, this.props.locationsObject, chartArrayNoSort, true)}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!count || (typeof s.data === 'undefined') || s.data.length === 0} />,
                  ]
                ))
              }
              { openTab === 1 && !vehicles &&
                chartArray.map( (s, idx) => (
                  [
                    <Area
                      key={'Female ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='women' stroke={colorsWomen[idx]} strokeWidth={this.getStroke()}
                      fill={colorsWomen[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Female: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                    <Area
                      key={'Male ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='men' stroke={colorsMen[idx]} strokeWidth={this.getStroke()}
                      fill={colorsMen[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Male: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,
                  ]
                ))
              }
              {openTab === 1 && vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Area
                      key={'Bikes ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='bikes' stroke={colorsBikes[idx]} strokeWidth={this.getStroke()}
                      fill={colorsBikes[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Bikes: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                    <Area
                      key={'Buses ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='buses' stroke={colorsBuses[idx]} strokeWidth={this.getStroke()}
                      fill={colorsBuses[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Buses: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                    <Area
                      key={'Cars ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='cars' stroke={colorsCars[idx]} strokeWidth={this.getStroke()}
                      fill={colorsCars[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Cars: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                    <Area
                      key={'Trucks ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='trucks' stroke={colorsTrucks[idx]} strokeWidth={this.getStroke()}
                      fill={colorsTrucks[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Trucks: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,
                  ]
                ))
              }
              { openTab === 2 && !vehicles &&
                chartArray.map( (s, idx) => (
                  [
                    <Area
                      key={'Adults ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='adults' stroke={colorsAdults[idx]} strokeWidth={this.getStroke()}
                      fill={colorsAdults[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Adults: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!age || (typeof s.data === 'undefined') || s.data.length === 0} />,

                    <Area
                      key={'Children ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='children' stroke={colorsChildren[idx]} strokeWidth={this.getStroke()}
                      fill={colorsChildren[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Children: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!age || (typeof s.data === 'undefined') || s.data.length === 0} />,

                    <Area
                      key={'Seniors ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                      yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='seniors' stroke={colorsSeniors[idx]} strokeWidth={this.getStroke()}
                      fill={colorsSeniors[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Seniors: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                      hide={!age || (typeof s.data === 'undefined') || s.data.length === 0} />,
                  ]
                ))
              }
                <Legend iconType='line' align='right' verticalAlign='top' layout='vertical' wrapperStyle={{ right: 0, top: 0 }} content={this.renderLegend}/>

              { (this.state.refAreaLeft && this.state.refAreaRight) &&
                <ReferenceArea yAxisId='1' x1={this.state.refAreaLeft} x2={this.state.refAreaRight} strokeOpacity={0.0} fillOpacity={0.25}/>
              }
              { this.state.refAreaLeft &&
                <ReferenceLine yAxisId='1' x={moment(this.state.refAreaLeft).isSameOrAfter(moment(this.state.refAreaRight)) ? this.state.refAreaRight : this.state.refAreaLeft} stroke='green' strokeDasharray='3 3'
                  label={{ position: (moment(this.state.refAreaRight).diff(moment(this.state.refAreaLeft), 'hours', true) > 3.15) ? 'insideBottomLeft' : 'insideBottomRight', offset: 6, fontSize: 18, value: moment(moment(this.state.refAreaLeft).isSameOrAfter(moment(this.state.refAreaRight)) ? this.state.refAreaRight: this.state.refAreaLeft).tz('America/New_York').add(0, 'minutes').format('h:mm A') }}/>
              }
              { this.state.refAreaRight &&
                <ReferenceLine yAxisId='1' x={moment(this.state.refAreaLeft).isSameOrAfter(moment(this.state.refAreaRight)) ? this.state.refAreaLeft : this.state.refAreaRight} stroke='red' strokeDasharray='3 3'
                  label={{ position: (moment(this.state.refAreaRight).diff(moment(this.state.refAreaLeft), 'hours', true) > 3.15) ? 'insideBottomRight' : 'insideBottomLeft', offset: 6, fontSize: 18, value: moment(moment(this.state.refAreaLeft).isSameOrAfter(moment(this.state.refAreaRight)) ? this.state.refAreaLeft: this.state.refAreaRight).tz('America/New_York').add(0, 'minutes').format('h:mm A') }}/>
              }
              </AreaChart>
            </ResponsiveContainer>
          </Grid>
        }
        { dataPoints > 0 && !multDates && (gender || age) &&
          // <ImageList container item className={classes.gridList} cols={numPlots > 1 ? 2 : 1} style={{ width: '97%', backgroundColor: 'white', marginTop: '0px', marginLeft: '0vw', paddingLeft: '2.5vw' }}>
          <ImageList className={classes.gridList} cols={numPlots > 1 ? 2 : 1} style={{ width: '97%', backgroundColor: 'white', marginTop: '0px', marginLeft: '0vw', paddingLeft: '2.5vw' }}>
        { chartArray.map( (s, idx) => (
          [
            <Grid container style={{ height: (windowWidth < this.state.CELLWIDTH && numPlots <= 2) ? (windowHeight / Math.round(numPlots / 2)) * 0.3 : (windowHeight / Math.round(numPlots / 2)) * 0.45, backgroundColor: 'white', display: s.data.length > 0 ? '' : 'none' }}>
              <Grid style={{ width: '100%', marginBottom: '1vh', marginRight: '0.5vw', paddingLeft: '1.5vh' }}>
                <Typography style={{ backgroundColor: alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.68), fontWeight: 700, fontSize: 'calc(6px + 0.4vw)' }}>
                  {s.locationLabels.join(', ')}
                </Typography>
              </Grid>
              <ResponsiveContainer width={'100%'} maxHeight={'100vh'} height={'100%'} minHeight={50}>
                <AreaChart
                  syncId='sync'
                  syncMethod='value'
                  style={{ cursor: 'pointer' }}
                  margin={{
                    left: windowWidth < 600 ? -windowWidth * 0.07 : -windowWidth * 0.009,
                    bottom: windowWidth < 600 ? -windowWidth * 0.0 : -windowHeight * 0.0,
                    right: windowWidth < 600 ? windowWidth * 0.02 : windowWidth * 0.015
                  }}
                >
                  <XAxis
                    type='number'
                    dataKey='_id.time'
                    domain={['dataMin', 'dataMax']}
                    style={{ fontSize: yAxisFontSize }}
                    // allowDuplicatedCategory={false}
                    dy={6}
                    interval={0}
                    scale='time'
                    ticks={ticksObj[idx].ticksArray}
                    tickFormatter={unixTime => moment(unixTime).tz('America/New_York').format('h A')}
                    // tick={<CustomizedAxisTick yshift={12} numPlotRows={(Math.round(numPlots / 2))}/>}
                    // label={{ value: 'Time of Day', position: 'bottom', fontSize: 'calc(10px + 0.4vw)', dy: (windowWidth / 60 - 15) }}
                  />
                  <YAxis
                    yAxisId='1'
                    type='number'
                    style={{ fontSize: yAxisFontSize }}
                    dx={0}
                    tickCount={6}
                    domain={[
                      dataMin => 0,
                      dataMax => (Math.round((dataMax * 1.5) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))
                    ]}
                    tickFormatter={
                      mode === 'Conversion'
                        ? (tickItem) => { return ((tickItem / 1).toLocaleString() + ' %') }
                        : (tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }
                    }
                  >
                    {/* <Label value= 'Counts' position= 'left' angle= {-90} dx={30-(windowWidth/60)} fontSize={'calc(10px + 0.4vw)'}/> */}
                  </YAxis>
                  <Tooltip position={{ y: 0 }} content={<CustomTooltip multDates={multDates} singleLocation={true} numPlots={numPlots} calIdx={idx} mode={mode} timeInterval={this.props.toolTipInterval} />} />

                  {gender && !vehicles &&
                    [
                      <Area
                        key={'Female ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='women' stroke={colorsWomen[idx]} strokeWidth={this.getStroke()}
                        fill={colorsWomen[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Female: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                      <Area
                        key={'Male ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='men' stroke={colorsMen[idx]} strokeWidth={this.getStroke()}
                        fill={colorsMen[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Male: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,
                    ]
                  }

                  {age && !vehicles &&
                    [
                      <Area
                        key={'Adults ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='adults' stroke={colorsAdults[idx]} strokeWidth={this.getStroke()}
                        fill={colorsAdults[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Adults: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!age || (typeof s.data === 'undefined') || s.data.length === 0} />,

                      <Area
                        key={'Children ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='children' stroke={colorsChildren[idx]} strokeWidth={this.getStroke()}
                        fill={colorsChildren[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Children: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!age || (typeof s.data === 'undefined') || s.data.length === 0} />,

                      <Area
                        key={'Seniors ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='seniors' stroke={colorsSeniors[idx]} strokeWidth={this.getStroke()}
                        fill={colorsSeniors[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Seniors: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!age || (typeof s.data === 'undefined') || s.data.length === 0} />,
                    ]
                  }

                  {vehicles &&
                    [
                      <Area
                        key={'Bikes ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='bikes' stroke={colorsBikes[idx]} strokeWidth={this.getStroke()}
                        fill={colorsBikes[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Bikes: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                      <Area
                        key={'Buses ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='buses' stroke={colorsBuses[idx]} strokeWidth={this.getStroke()}
                        fill={colorsBuses[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Buses: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                      <Area
                        key={'Cars ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='cars' stroke={colorsCars[idx]} strokeWidth={this.getStroke()}
                        fill={colorsCars[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Cars: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,

                      <Area
                        key={'Trucks ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDates & idx === (chartArray.length - 1) ? ' DC' : '')}
                        yAxisId='1' isAnimationActive={true} type='monotoneX' data={s.data} dataKey='trucks' stroke={colorsTrucks[idx]} strokeWidth={this.getStroke()}
                        fill={colorsTrucks[idx]} fillOpacity={0.5} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                        name={'Trucks: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')}
                        hide={!gender || (typeof s.data === 'undefined') || s.data.length === 0} />,
                    ]
                  }

                  {/* <Legend iconType='line' align='center' verticalAlign='top' layout='vertical'
                    wrapperStyle={{ right: (multDates ? (windowWidth / 30 + 100) : 0) + (this.state.multLocs ? (windowWidth / 30 + 100) : 0), top: 5 + windowWidth / 200 }}
                    content={this.renderLegend}
                  /> */}
                </AreaChart>
              </ResponsiveContainer>
            </Grid>
          ]
        ))}
          </ImageList>
        }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(ByTimeChart));
